// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bike-pledge-js": () => import("./../../../src/pages/bikePledge.js" /* webpackChunkName: "component---src-pages-bike-pledge-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-endorsements-js": () => import("./../../../src/pages/endorsements.js" /* webpackChunkName: "component---src-pages-endorsements-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-gnd-pledge-js": () => import("./../../../src/pages/gndPledge.js" /* webpackChunkName: "component---src-pages-gnd-pledge-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

